import reviewImg1 from '../../../../assets/review1.webp';
import reviewImg2 from '../../../../assets/review2.webp';
import reviewImg3 from '../../../../assets/review3.webp';
import reviewImg4 from '../../../../assets/review4.webp';

export const petBoardingReviews = [
  {
    rating: 5,
    name: 'Meredith P',
    src: reviewImg1,
    review: "The Wag! app is great for times I can't get home to walk Rafa. The walkers leave a nice recap of my dog's behavior! Best of all, Rafa absolutely loves meeting new people who come and walk him!",
  },
  {
    rating: 5,
    name: 'Brenda H',
    src: reviewImg2,
    review:
      'This is the best thing for my dog and I. Harvey loves getting to meet new people and going on long walks. I’m not worried about him not getting enough exercise!',
  },
  {
    rating: 5,
    name: 'Jason F',
    src: reviewImg3,
    review: "My wife and I are happy with Wag! We've used both sitting and dog walking services. It's nice knowing we can have a nice date night knowing that Dash is safe, and is having fun also!",
  },
  {
    rating: 5,
    name: 'Stephanie S',
    src: reviewImg4,
    review:
      "I've been using Wag! for a little over 6 months and really love the convenience of being able to have someone take care of my pup while I'm away for work.",
  },
];
