import React, { useState } from 'react';
import { ChevronRightIcon } from 'lucide-react';

import { TWButton } from '../../../../../tw-components/ui/button';
import {
  Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '../../../../../tw-components/ui/tooltip';
import { cn } from '../../../../../utils/tailwind/cn';

type AnchorProps = {
  onClick?: () => void;
  isDisabled?: boolean;
  containerClassName?: string;
  tooltipText?: string;
  cySelector?: string;
  tooltipTriggerClassName?: string;
  chevronClassName?: string;
  variant?: 'default' | 'booking-journey' | 'booking-journey-secondary' | 'calendar-booking-journey' | 'partner';
}

export const FindCaregiverButton = ({
  onClick,
  isDisabled,
  containerClassName,
  tooltipText,
  variant='booking-journey',
  tooltipTriggerClassName,
  chevronClassName,
  cySelector,
}: AnchorProps) => {
  const [open, setOpen] = useState(false);


  const tooltipEnabled = isDisabled && !!tooltipText;
  return (
    <TooltipProvider
      delayDuration={100}
    >
      <Tooltip
        open={open}
      >
        <TooltipTrigger
          asChild
          className={isDisabled ? 'cursor-help' : 'cursor-pointer'}
        >
          <span
            onMouseEnter={() => {
              if (tooltipEnabled) {
                setOpen(true);
              }
            }}
            onFocus={() => {
              if (tooltipEnabled) {
                setOpen(true);
              }
            }}
            onBlur={() => {
              setOpen(false);
            }}
            onMouseLeave={() => {
              setOpen(false);
            }}
            className={cn(tooltipTriggerClassName)}
          >
            <TWButton
              size="pill"
              variant={variant}
              data-cy={cySelector}
              className={cn(
                'mx-auto md:px-10',
                'hover:opacity-95',
                '!w-full ml-auto',
                containerClassName)}
              disabled={isDisabled}
              onClick={onClick}
            >
              Find a local Pet Caregiver
              <ChevronRightIcon
                className={cn('w-4 h-4 ml-2', isDisabled ? 'text-darkGray/50' : 'text-white', chevronClassName)}
                strokeWidth={3}
              />
            </TWButton>
          </span>

        </TooltipTrigger>

        {tooltipEnabled && (
          <TooltipContent
            side="top"
            className="border border-gray200 relative -bottom-[36px]"
          >
            <p className="text-[12px] text-darkGray font-[400]">
              {tooltipText}
            </p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};
