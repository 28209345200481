import clsx from 'clsx';
import React from 'react';

import { FindCaregiverButton } from '../hero/FindCaregiverButton';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';
import { HowToBookItems, howToBookItems } from './data';
import EmblaCarousel from '../components/EmblaCarousel';
import { IS_BROWSER } from '../../BoneSpinner';
import { cn } from '../../../../../utils/tailwind/cn';
import { SectionSubTitle } from '../../../../../tw-components/SectionSubtitle';

export type HowToBookProps = {
  buttonClassName?: string;
  sectionTitleClassName?: string;
  sectionSubtitleClassName?: string;
  numberIconsClassName?: (index:number) => string;
  sectionContainerClassName?: string;
  howToBookTitle?: string;
  howToBookSubtitle?: string;
  items?: HowToBookItems;
  buttonAbove?: boolean;
  serviceLinks?: boolean;
};

export const HowToBook = ({
  buttonClassName,
  sectionTitleClassName,
  sectionSubtitleClassName,
  sectionContainerClassName,
  howToBookTitle,
  howToBookSubtitle,
  numberIconsClassName,
  buttonAbove = true,
  serviceLinks = false,
  items = howToBookItems,
}: HowToBookProps) => (
  <div>
    <SectionContainer
      className={cn(
        '!max-w-[1150px] flex flex-col  md:mb-0',
        serviceLinks ? 'mb-[30px] md:mb-[40px] lg:mb-[40px]' : 'mb-[80px]',
        sectionContainerClassName,
      )}
    >
      <SectionTitle
        text={howToBookTitle}
        className={cn('mb-6 lg:mb-[30px]', sectionTitleClassName)}
      />
      {howToBookSubtitle && (
        <SectionSubTitle
          text={howToBookSubtitle}
          className={cn(sectionSubtitleClassName)}
        />
      )}

      {buttonAbove && (
        <FindCaregiverButton
          containerClassName={cn('!py-4 w-[300px]', buttonClassName)}
          tooltipTriggerClassName="mx-auto w-[80%] max-w-[300px] text-center"
          onClick={() => {
            const bookingFilters = document.querySelector<HTMLDivElement>('#booking-filters');
            if (bookingFilters && IS_BROWSER) {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
              const modalTriggerButton = document.querySelector<HTMLButtonElement>('#filters-modal-trigger');
              if (modalTriggerButton) {
                modalTriggerButton.click();
              }
            }
          }}
        />
      )}

      <EmblaCarousel
        emblaDotsContainer="left-[calc(50%-48px)]"
      >
        {items.map((item, index) => (
          <div
            key={item.title}
            className={clsx(
              'flex flex-col justify-center shadow-[0px_4px_10px_-1px_rgba(0,0,0,0.1)] relative',
              'px-4 pb-4 pt-[100px] mt-[170px] z-10 rounded-lg items-center mb-2',
              'max-w-[400px] mx-auto min-w-[250px] first:ml-[10%] mr-[5%] last:mr-2',
              'md:w-[30%] md:px-6 md:mt-[200px] md:mb-0',
              'first:md:ml-0 md:mr-0 md:mx-auto md:last:mr-0',
            )}
          >
            <img
              src={item.icon}
              loading="lazy"
              className="w-[230px] h-[230px] mb-4 md:mb-6 absolute top-[-150px]"
              alt={item.title}
            />
            <p
              className={cn(
                'rounded-full mx-auto flex items-center justify-center h-[28px] w-[28px] my-4 text-darkGray font-[600]',
                index === 0 && 'bg-[#F9CD46]/50 border border-[#F9CD46]',
                index === 1 && 'bg-[#7786B7]/50 border border-[#7786B7]',
                index === 2 && 'bg-[#00BF8F]/50 border border-[#00BF8F]',
                numberIconsClassName && numberIconsClassName(index),
              )}
            >
              {index + 1}
            </p>
            <h4 className="mb-2 text-darkGray text-center">{item.title}</h4>
            <p className="text-wagGray text-center !leading-[1.6]">{item.description}</p>
          </div>
        ))}
      </EmblaCarousel>
    </SectionContainer>

    {!buttonAbove && (
      <FindCaregiverButton
        containerClassName={cn('flex !py-4 !w-[80%] max-w-[300px] self-center', buttonClassName)}
        tooltipTriggerClassName="mx-auto w-[80%] max-w-[300px] text-center"
        onClick={() => {
          const bookingFilters = document.querySelector<HTMLDivElement>('#booking-filters');
          if (bookingFilters && IS_BROWSER) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            const modalTriggerButton = document.querySelector<HTMLButtonElement>('#filters-modal-trigger');
            if (modalTriggerButton) {
              modalTriggerButton.click();
            }
          }
        }}
      />
    )}
  </div>
);
