import React from 'react';

export const ChatIcon = () => (
  <div
    className="mr-4"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
    >
      <path
        stroke="hsl(var(--twc-chat))"
        strokeWidth="2.125"
        d="M17 31.167c7.824 0 14.167-6.343 14.167-14.167S24.824 2.833 17 2.833 2.833 9.176 2.833 17c0 2.267.533 4.409 1.478 6.308.252.505.336 1.081.19 1.627l-.843 3.153a1.842 1.842 0 0 0 2.254 2.254l3.153-.843a2.314 2.314 0 0 1 1.628.189A14.108 14.108 0 0 0 17 31.167Z"
      />
      <path
        fill="hsl(var(--twc-chat))"
        d="m17 13.458-.76.742a1.061 1.061 0 0 0 1.52 0l-.76-.74v-.002Zm-2.105 6.9c-.823-.699-1.65-1.512-2.264-2.35-.625-.858-.944-1.629-.944-2.27H9.563c0 1.297.616 2.513 1.352 3.521.75 1.027 1.715 1.961 2.604 2.719l1.377-1.62h-.001Zm-3.207-4.62c0-1.569.7-2.393 1.42-2.664.734-.273 1.902-.127 3.133 1.13l1.518-1.487c-1.637-1.671-3.656-2.283-5.394-1.633-1.75.654-2.803 2.43-2.803 4.654h2.126Zm8.793 6.242c.89-.757 1.854-1.692 2.604-2.718.736-1.01 1.352-2.225 1.352-3.523h-2.125c0 .642-.318 1.412-.943 2.27-.613.84-1.44 1.651-2.264 2.351l1.376 1.62Zm3.956-6.241c0-2.224-1.052-4-2.802-4.655-1.738-.65-3.757-.04-5.394 1.633l1.518 1.488c1.231-1.258 2.4-1.404 3.131-1.13.723.269 1.422 1.096 1.422 2.664h2.125Zm-10.918 6.24c1.105.94 1.984 1.743 3.481 1.743v-2.125c-.587 0-.874-.191-2.105-1.238l-1.376 1.62Zm5.586-1.619c-1.23 1.047-1.518 1.237-2.105 1.237v2.125c1.497 0 2.377-.803 3.48-1.742l-1.376-1.62h.001Z"
      />
    </svg>
  </div>
);
