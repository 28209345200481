import React from 'react';

export const HandIcon = () => (
  <div
    className="mr-4"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      fill="none"
    >
      <path
        fill="hsl(var(--twc-hand))"
        d="m17 4.9-.72.782a1.063 1.063 0 0 0 1.438 0L17 4.9Zm-1.996 7.211c-.978-.713-2.02-1.58-2.808-2.493-.813-.942-1.217-1.777-1.217-2.438H8.854c0 1.44.819 2.768 1.733 3.826.939 1.088 2.126 2.064 3.166 2.822l1.25-1.717ZM10.979 7.18c0-1.5.737-2.356 1.624-2.654.923-.312 2.3-.11 3.677 1.156l1.438-1.564c-1.809-1.663-3.97-2.218-5.793-1.606-1.86.626-3.07 2.374-3.07 4.668h2.124Zm9.268 6.648c1.04-.756 2.227-1.734 3.166-2.822.914-1.058 1.733-2.386 1.733-3.826H23.02c0 .661-.402 1.496-1.217 2.438-.788.913-1.83 1.78-2.808 2.493l1.251 1.717Zm4.899-6.648c0-2.294-1.211-4.042-3.07-4.668-1.822-.612-3.985-.057-5.794 1.606l1.436 1.564c1.379-1.266 2.757-1.468 3.68-1.156.886.298 1.623 1.153 1.623 2.654h2.125Zm-11.393 6.648c1.07.78 1.918 1.442 3.247 1.442v-2.125c-.517 0-.8-.163-1.996-1.034l-1.251 1.717Zm5.242-1.717c-1.195.871-1.478 1.034-1.995 1.034v2.125c1.329 0 2.177-.662 3.247-1.442l-1.25-1.717h-.002Z"
      />
      <path
        stroke="hsl(var(--twc-hand))"
        strokeLinecap="round"
        strokeWidth="2.125"
        d="M7.083 29.383h3.202c1.43 0 2.88.15 4.273.436 2.486.511 5.044.568 7.55.168 1.23-.199 2.437-.503 3.53-1.03.987-.478 2.195-1.148 3.007-1.9.81-.751 1.655-1.98 2.253-2.94.515-.824.266-1.834-.547-2.448a2.673 2.673 0 0 0-3.145 0l-2.56 1.934c-.992.75-2.076 1.44-3.366 1.646-.156.024-.319.047-.489.067m0 0-.156.017m.156-.017c.227-.062.435-.178.605-.34a2.113 2.113 0 0 0 .178-3.023c-.184-.205-.4-.38-.637-.52-3.963-2.365-10.13-.564-13.854 2.078m13.708 1.805a.735.735 0 0 1-.156.017m0 0c-.854.086-1.715.088-2.57.005"
      />
      <path
        stroke="hsl(var(--twc-hand))"
        strokeWidth="2.125"
        d="M7.083 22.458a2.125 2.125 0 1 0-4.25 0v7.084a2.125 2.125 0 0 0 4.25 0v-7.084Z"
      />
    </svg>
  </div>
);
