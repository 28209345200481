import { CalendarIcon } from '../../components/icons/CalendarIcon';
import { ChatIcon } from '../../components/icons/ChatIcon';
import { ClipboardIcon } from '../../components/icons/ClipboardIcon';
import { CupIcon } from '../../components/icons/CupIcon';
import { HandIcon } from '../../components/icons/HandIcon';
import { ShelterIcon } from '../../components/icons/ShelterIcon';

export const descriptionItems = [
  {
    title: 'We’ve been doing this a long time',
    description: 'Wag! invented on-demand dog walking in 2015, and now we have over 450,000 Pet Caregivers and 5 million pet parents counting on us for walking, sitting and more!',
    icon: CupIcon,
  },
  {
    title: 'Don’t just take our word for it!',
    description: 'Thousands of pet parents have left 5-star reviews about the fantastic Pet Caregivers on Wag! So wherever you are in the US, you know you can trust Wag!',
    icon: ChatIcon,
  },
  {
    title: 'Every Pet Caregiver has been vetted',
    description: 'Having someone look after your dog is a big deal, so every Pet Caregiver has to pass extensive background checks – meaning you’ll always get the pick of the litter.',
    icon: ClipboardIcon,
  },
  {
    title: 'We’re here for you 7 days a week',
    description: 'We pride ourselves on being available to help you any time, day or night. Customer support is available and easily accessible through the Wag! app.',
    icon: CalendarIcon,
  },
  {
    title: 'Every Wag! Walk feeds a shelter dog',
    description: `Every time someone books a walk with us, a portion of the proceeds helps to feed a shelter dog in your area. We’ve donated over 16.5 million meals – and counting!`,
    icon: ShelterIcon,
  },
  {
    title: 'Whatever you need, we’ve got your back',
    description: 'Not only can you book pet sitting with a 5-star rated Pet Caregiver in your area, but Wag! can also help you with pet health, nutrition advice, pet care, and training.',
    icon: HandIcon,
  },
];
