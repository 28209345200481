import React from 'react';

export const CalendarIcon = () => (
  <div
    className="mr-4"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      fill="none"
    >
      <path
        stroke="hsl(var(--twc-calendar))"
        strokeWidth="2.125"
        d="M2.833 17.5c0-5.342 0-8.014 1.66-9.673 1.66-1.66 4.331-1.66 9.674-1.66h5.666c5.343 0 8.014 0 9.673 1.66 1.66 1.659 1.66 4.33 1.66 9.673v2.833c0 5.343 0 8.014-1.66 9.673-1.659 1.66-4.33 1.66-9.673 1.66h-5.666c-5.343 0-8.014 0-9.673-1.66-1.66-1.659-1.66-4.33-1.66-9.673V17.5Z"
      />
      <path
        stroke="hsl(var(--twc-calendar))"
        strokeLinecap="round"
        strokeWidth="2.125"
        d="M9.916 6.167V4.042m14.167 2.125V4.042M3.542 13.25h26.916"
      />
      <path
        fill="hsl(var(--twc-calendar))"
        d="M25.5 24.583a1.417 1.417 0 1 1-2.834 0 1.417 1.417 0 0 1 2.834 0Zm0-5.666a1.417 1.417 0 1 1-2.834 0 1.417 1.417 0 0 1 2.834 0Zm-7.083 5.666a1.417 1.417 0 1 1-2.834 0 1.417 1.417 0 0 1 2.834 0Zm0-5.666a1.417 1.417 0 1 1-2.834 0 1.417 1.417 0 0 1 2.834 0Zm-7.084 5.666a1.417 1.417 0 1 1-2.833 0 1.417 1.417 0 0 1 2.833 0Zm0-5.666a1.417 1.417 0 1 1-2.833 0 1.417 1.417 0 0 1 2.833 0Z"
      />
    </svg>
  </div>
);
