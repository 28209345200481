import clsx from 'clsx';
import React from 'react';
import { Stars } from '../../header/Stars';
import { cn } from '../../../../../utils/tailwind';

export type HeroTitleProps = {
  containerClassName?: string;
  title?: string;
  titleClassName?: string;
  subtitle?: string;
  tagline?: string;
  taglineClassName?: string;
}

export const HeroTitle = ({
  containerClassName,
  title,
  tagline = 'Book a 5-star Pet Caregiver near you',
  subtitle,
  titleClassName,
  taglineClassName,
}: HeroTitleProps) => (
  <div
    className={clsx('md:mx-auto md:px-8', containerClassName)}
  >
    <h1 className={cn('text-white text-center md:text-left max-w-[660px]', titleClassName)}>
      {title}
    </h1>

    {subtitle && (
      <p className="text-white mb-3 md:mb-6 text-center md:text-left max-sm:w-[90%] mx-auto">
        {subtitle}
      </p>
    )}

    <div
      className="flex flex-wrap justify-center my-1 md:justify-start items-center"
    >
      <Stars
        count={5}
        className="pb-1"
      />
      <p className={cn('text-white ml-2 text-xs', taglineClassName)}>
        {tagline}
      </p>
    </div>
  </div>
);
