import React from 'react';

export const ClipboardIcon = () => (
  <div
    className="mr-4"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      fill="none"
    >
      <path
        stroke="hsl(var(--twc-clipboard))"
        strokeWidth="2.125"
        d="M22.667 6.167c3.08.017 4.75.154 5.838 1.242 1.245 1.245 1.245 3.249 1.245 7.255v8.5c0 4.008 0 6.01-1.245 7.256-1.244 1.244-3.249 1.244-7.255 1.244h-8.5c-4.006 0-6.01 0-7.255-1.244-1.245-1.247-1.245-3.248-1.245-7.256v-8.5c0-4.006 0-6.01 1.245-7.255 1.088-1.088 2.757-1.225 5.838-1.242"
      />
      <path
        stroke="hsl(var(--twc-clipboard))"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.125"
        d="m12.75 19.483 2.428 2.267 6.072-5.667"
      />
      <path
        stroke="hsl(var(--twc-clipboard))"
        strokeWidth="2.125"
        d="M11.333 5.458a2.125 2.125 0 0 1 2.125-2.125h7.084a2.125 2.125 0 0 1 2.125 2.125v1.417A2.125 2.125 0 0 1 20.542 9h-7.084a2.125 2.125 0 0 1-2.125-2.125V5.458Z"
      />
    </svg>
  </div>
);
