import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel';

import clsx from 'clsx';
import {
  DotButton,
  PrevButton,
  NextButton,
} from './EmblaCarouselNav';
import { cn } from '../../../../../utils/tailwind/cn';

type Props = {
  options?: EmblaOptionsType;
  children?: React.ReactNode;
  emblaClassName?: string;
  emblaViewportClassName?: string;
  emblaContainerClassName?: string;
  navContainerClassName?: string;
  dotButtonClassName?: string;
  emblaDotsContainer?: string;
}

const EmblaCarousel = ({
  options,
  emblaClassName = 'md:py-0',
  emblaViewportClassName,
  emblaContainerClassName,
  dotButtonClassName,
  navContainerClassName,
  emblaDotsContainer = 'left-[calc(50%-64px)]',
  children,
}: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  );
  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi],
  );

  const onInit = useCallback((eA: EmblaCarouselType) => {
    if (eA) {
      setScrollSnaps(eA.scrollSnapList());
    }
  }, []);

  const onSelect = useCallback((eA: EmblaCarouselType) => {
    if (!eA) return;
    setSelectedIndex(eA.selectedScrollSnap());
    setPrevBtnDisabled(!eA.canScrollPrev());
    setNextBtnDisabled(!eA.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <div className={cn(
      'py-[1.6rem] px-0',
      emblaClassName)}
    >
      <div
        className={clsx(
          'overflow-hidden',
          emblaViewportClassName,
        )}
        ref={emblaRef}
      >
        <div className={clsx(
          'backface-invisible flex touch-pan-y pb-[1rem] mr-[calc(1rem)] md:mr-0 md:px-[2px]',
          emblaContainerClassName)}
        >
          {children}
        </div>
      </div>

      <div className={cn(
        'flex items-center justify-between translate-y-[-50%]',
        'left-[2rem] bottom-[-30px] w-[calc(100%-4rem)] absolute',
        'md:hidden',
        navContainerClassName,
      )}
      >
        <PrevButton
          onClick={scrollPrev}
          disabled={prevBtnDisabled}
        />
        <NextButton
          onClick={scrollNext}
          disabled={nextBtnDisabled}
        />
      </div>
      <div className={clsx(
        'z-[1] absolute bottom-[-10px] right-0 flex justify-center items-center w-fit md:hidden',
        emblaDotsContainer,
      )}
      >
        {scrollSnaps.map((_, index) => (
          <DotButton
            // eslint-disable-next-line react/no-array-index-key
            key={String(index)}
            onClick={() => scrollTo(index)}
            className={clsx('appearance-none touch-manipulation bg-transparent flex pointer',
              'mx-[6px] justify-center items-center w-[20px] h-[20px]',
              'after:border after:border-[#E5E5E5] after:rounded-full',
              'after:content-[""] after:h-[16px] after:w-[16px] after:outline-offset-1',
              index === selectedIndex
                ? 'after:outline after:outline-2 after:outline-secondary after:bg-secondary'
                : 'after:bg-transparent',
              dotButtonClassName,
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default EmblaCarousel;
