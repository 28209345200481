import React from 'react';

export const ShelterIcon = () => (
  <div
    className="mr-4"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      fill="none"
    >
      <path
        stroke="hsl(var(--twc-shelter))"
        strokeWidth="2.125"
        d="M2.833 17.789c0-3.243 0-4.864.737-6.207.734-1.344 2.078-2.177 4.766-3.846l2.833-1.758C14.009 4.214 15.43 3.333 17 3.333c1.57 0 2.99.881 5.831 2.645l2.833 1.758c2.688 1.67 4.032 2.502 4.767 3.846.736 1.343.736 2.964.736 6.206v2.156c0 5.525 0 8.289-1.66 10.006-1.66 1.717-4.331 1.717-9.674 1.717h-5.666c-5.343 0-8.014 0-9.673-1.717-1.66-1.717-1.66-4.48-1.66-10.006v-2.155Z"
      />
      <path
        stroke="hsl(var(--twc-shelter))"
        strokeLinecap="round"
        strokeWidth="2.125"
        d="M12.75 23.166A7.11 7.11 0 0 0 17 24.584a7.11 7.11 0 0 0 4.25-1.416"
      />
    </svg>
  </div>
);
