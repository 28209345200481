import clsx from 'clsx';
import React from 'react';

import { Stars } from '../../header/Stars';
import EmblaCarousel from '../components/EmblaCarousel';
import { SectionContainer } from '../components/SectionContainer';
import { petBoardingReviews } from './data';
import { cn } from '../../../../../utils/tailwind';
import { SectionTitle } from '../components/SectionTitle';
export type ServiceReviewsProps = {
  sectionContainerClassName?: string;
  sectionTitle?: string;
};
export const ServiceReviews = ({
  sectionContainerClassName,
  sectionTitle,
}: ServiceReviewsProps) => (
  <SectionContainer
    className={cn('max-w-[1150px] mb-[80px] mt-0', sectionContainerClassName)}
  >
    {sectionTitle && (
      <SectionTitle
        text={sectionTitle}
      />
    )}
    <EmblaCarousel>
      {petBoardingReviews.slice(0, 4).map((item) => (
        <div
          key={item.review}
          className={clsx(
            'flex flex-col shadow-[0px_3px_10px_-1px_rgba(0,0,0,0.1)] rounded-md w-full max-w-[300px] mx-auto md:mx-0 lg:w-[24%] overflow-hidden min-h-[420px]',
            'min-w-[250px] lg:min-w-[initial] first:ml-[5%] mr-[5%] last:mr-2',
            'first:md:ml-0 md:mr-0 md:mx-auto md:last:mr-0',
          )}
        >
          <img
            src={item.src}
            alt="review"
            loading="lazy"
            className="w-full h-[150px] object-cover"
          />

          <div
            className="p-5 md:p-6 flex flex-col h-full"
          >
            <Stars
              count={item.rating}
            />
            <p className="mt-3 mb-1 text-[14px] line-clamp-7">{item.review}</p>
            <h4 className="mt-auto text-[14px]">{item.name}</h4>
          </div>
        </div>
      ))}
    </EmblaCarousel>
  </SectionContainer>
);
